<template>
  <div>
    <ZebraboxDataTable
      apipath="locations"
      :headers="[
        {
          text: 'Name',
          value: `name.${$store.state.languages[0]}`,
        },
        {
          text: 'Site Code',
          value: 'sitecode',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'City',
          value: `city.${$store.state.languages[0]}`,
        },
        {
          text: 'Request type',
          value: 'requesttypename',
        },
        {
          text: 'Location Sizes',
          value: 'sizes',
          sortable: false,
          type: 'mapnames',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
      @specialAction="toggleEnabled"
      specialActionIcon="far fa-check-square-o"
      :item_class="function(item) { return item.enabled ? '' : 'disabledLocation' }"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-tabs v-model="editTab">
          <v-tab key="0">
            General
          </v-tab>
          <v-tab key="1">
            Location Sizes
          </v-tab>
          <v-tab key="2">
            Images
          </v-tab>
          <v-tab key="3">
            Alternative Locations
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="editTab">
          <v-tab-item key="0">
            <lang-tabs :model="item" :config="{
              name: {
                label: 'Name'
              }
            }" />
            <v-select
              label="Request type"
              v-model="item.requesttype"
              :items="requesttypes"
              item-text="name"
              item-value="id"
            ></v-select>
            <lang-tabs :model="item" :config="{
              city: {
                label: 'City',
              },
              specialOffer: {
                label: 'Special offer',
              },
              workdayHours: {
                label: 'Workday hours',
              },
              weekendHours: {
                label: 'Weekend hours',
              },
              accessToStorage: {
                label: 'Access to storage',
              },
              yourBoxAdditionalInfo: {
                label: 'Your box: additional information',
                textArea: true,
              },
            }" />
            <v-text-field label="Site Code" v-model="item.siteCode" />
            <v-text-field label="Email" v-model="item.email" />
            <v-text-field label="Address" v-model="item.address" />
            <v-text-field label="Latitude" v-model="item.latitude" />
            <v-text-field label="Longitude" v-model="item.longitude" />
            <v-text-field label="Zip" v-model="item.zip" />
            <v-text-field label="Phone" v-model="item.phone" />
            <v-text-field label="Payment terminal number" v-model="item.paymentterminal" />
            <v-menu
              v-model="item.launchdateModal"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.launchdateFormatted"
                  label="Available from"
                  prepend-icon="fal fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @blur="item.launchdate =
                        parseDate(item.launchdateFormatted)"
                  @click:clear="item.launchdate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.launchdate"
                @input="onLaunchDatePickerInput(item)"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="item.lastdateModal"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.lastdateFormatted"
                  label="Available to"
                  prepend-icon="fal fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @blur="item.lastdate =
                        parseDate(item.lastdateFormatted)"
                  @click:clear="item.lastdate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.lastdate"
                @input="onLastDatePickerInput(item)"
              ></v-date-picker>
            </v-menu>
            <v-checkbox label="Active" v-model="item.enabled"></v-checkbox>
          </v-tab-item>
          <v-tab-item key="1">
            <v-data-table
              :headers="[
                {
                  'text':'Storage Size',
                  'value':'size',
                },
                {
                  'text':'Volume',
                  'value':'volume',
                },
                {
                  'text':'Surface',
                  'value':'surface',
                },
                {
                  'text':'',
                  'value':'actions',
                  'sortable':false,
                },
              ]"
              :items="item.sizes"
              :options="{
                itemsPerPage: -1
              }"
            >
              <template v-slot:item.size="{ item }">
                <v-select
                  v-model="item.size"
                  :items="storagesizes"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </template>
              <template v-slot:item.volume="{ item }">
                <v-text-field v-model="item.volume" />
              </template>
              <template v-slot:item.surface="{ item }">
                <v-text-field v-model="item.surface" />
              </template>
              <template v-slot:item.actions="slotProps">
                <v-icon
                  small
                  @click="deleteStoragesizeItem(slotProps.item, item)"
                >
                  far fa-trash
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-toolbar
                  flat
                >
                  <v-spacer />
                  <v-btn color="primary" class="mb-2" @click="item.sizes.push({
                    size: undefined,
                    volume: '',
                    surface: '',
                  })">
                    New Entry
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="2">
            <img
              class="locationPreview"
              v-if="item.inactiveImageNewUtf8"
              :src="`data:${item.inactiveImageNewType};base64,${item.inactiveImageNewUtf8}`"
            >
            <img v-else-if="item.hasActiveImage"
              class="locationPreview"
              :src="`/api/image_inactive?sitecode=${item.siteCode}`"
            >
            <v-file-input
              label="Location inactive"
              v-model="item.inactiveImageNew"
              @change="setInactiveFile(item)"
            />
            <img
              class="locationPreview"
              v-if="item.activeImageNewUtf8"
              :src="`data:${item.activeImageNewType};base64,${item.activeImageNewUtf8}`"
            >
            <img v-else-if="item.hasInactiveImage"
              class="locationPreview"
              :src="`/api/image_active?sitecode=${item.siteCode}`"
            >
            <v-file-input
              label="Location active"
              v-model="item.activeImageNew"
              @change="setActiveFile(item)"
            />
          </v-tab-item>
          <v-tab-item key="3">
            <v-combobox
              v-model="item.altLocations"
              :items="locationsWithout(item.id)"
              chips
              clearable
              label="Alternative locations"
              multiple
              item-value="id"
              item-text="name"
              solo>
            </v-combobox>
          </v-tab-item>
        </v-tabs-items>
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'Locations',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      requesttypes: [
        {
          id: 1,
          name: 'Booking',
        },
        {
          id: 2,
          name: 'Reservation',
        },
        {
          id: 0,
          name: 'Offer',
        },
      ],
      locations: [],
      storagesizes: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getLocations().then((data) => {
      this.locations = data.data;
    });
    BackendService.getStorageSizes().then((data) => {
      this.storagesizes = data.data;
    });
  },
  methods: {
    setInactiveFile(item) {
      const read = new FileReader();
      const file = item.inactiveImageNew;
      read.readAsBinaryString(file);

      read.onloadend = () => {
        item.inactiveImageNewType = file.type; // eslint-disable-line no-param-reassign
        item.inactiveImageNewUtf8 = btoa(read.result); // eslint-disable-line no-param-reassign
      };
    },
    setActiveFile(item) {
      const read = new FileReader();
      const file = item.activeImageNew;
      read.readAsBinaryString(file);

      read.onloadend = () => {
        item.activeImageNewType = file.type; // eslint-disable-line no-param-reassign
        item.activeImageNewUtf8 = btoa(read.result); // eslint-disable-line no-param-reassign
      };
    },
    locationsWithout(id2exclude) {
      return this.locations.filter((item) => item.id !== id2exclude);
    },
    onLaunchDatePickerInput(item) {
      const myItem = item;
      myItem.launchdateModal = false;
      myItem.launchdateFormatted = this.formatDate(myItem.launchdate);
    },
    onLastDatePickerInput(item) {
      const myItem = item;
      myItem.lastdateModal = false;
      myItem.lastdateFormatted = this.formatDate(myItem.lastdate);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    newItem(action) {
      action({
        default: 0,
        firm: 0,
        name: {
          de: '',
          fr: '',
          en: '',
        },
        city: {
          de: '',
          fr: '',
          en: '',
        },
        specialOffer: {
          de: '',
          fr: '',
          en: '',
        },
        workdayHours: {
          de: '',
          fr: '',
          en: '',
        },
        weekendHours: {
          de: '',
          fr: '',
          en: '',
        },
        accessToStorage: {
          de: '',
          fr: '',
          en: '',
        },
        yourBoxAdditionalInfo: {
          de: '',
          fr: '',
          en: '',
        },

        sizes: [],
      });
    },
    editItem(item, action) {
      BackendService.updateLocation(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this location?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    toggleEnabled(item, action) {
      BackendService.toggleLocation(item.id);
      action();
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteLocation(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
    deleteStoragesizeItem(subitem, item) {
      this.deleteStoragesizeItemSubItem = subitem;
      this.deleteStoragesizeItemItem = item;
      this.confirmDialogText = 'Are you sure you want to delete this storage size?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteStoragesizeItemConfirm;
    },
    deleteStoragesizeItemConfirm() {
      this.deleteStoragesizeItemItem.sizes.splice(
        this.deleteStoragesizeItemItem.sizes.indexOf(this.deleteStoragesizeItemSubItem),
        1,
      );
      this.confirmDialog = false;
    },
  },
};
</script>
<style>
.locationPreview {
  max-height: 380px;
  max-width: 380px;
}
tr.disabledLocation {
  background: repeating-linear-gradient(
    45deg,
    #FFF,
    #FFF 10px,
    #EEE 10px,
    #EEE 20px
  );
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody
> tr.disabledLocation:hover
:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: repeating-linear-gradient(
    45deg,
    #DDD,
    #DDD 10px,
    #EEE 10px,
    #EEE 20px
  );
}
</style>
